// src/store/index.js
import { createStore } from 'vuex'
import axios from '../axiosInstance'

export default createStore({
    state: {
        user: null,
        token: localStorage.getItem('token') || '',
        permissions: JSON.parse(localStorage.getItem('permissions')) || [] // 初始化时从localStorage获取权限
    },
    mutations: {
        setUser(state, user) {
            state.user = user
                //localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('user', JSON.stringify(user));
        },
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        setPermissions(state, permissions) {
            state.permissions = permissions
            localStorage.setItem('permissions', JSON.stringify(permissions)) // 存储权限到localStorage
        },
        clearAuthData(state) {
            state.user = null
            state.token = ''
            state.permissions = []
            localStorage.removeItem('token')
            localStorage.removeItem('permissions') // 清除localStorage中的权限数据
        }
    },
    actions: {
        login({ commit }, authData) {
            return axios.post('/api/apifoadmin/auth/login', authData)
                .then(response => {
                    if (response.data.status === 'success') {
                        const user = response.data.user || {};
                        const permissions = response.data.permissions || [];

                        // console.log('Loaded permissions:', permissions); // 添加日志查看加载的权限

                        commit('setUser', user);
                        commit('setToken', response.data.token);
                        commit('setPermissions', permissions); // 确保权限正确设置
                    } else {
                        throw new Error('Login failed');
                    }
                })
                .catch(error => {
                    console.error('登录失败', error.message);
                    throw error;
                });
        },
        logout({ commit }) {
            commit('clearAuthData');
        }
    }
})