<template>
  <div>
    <p style="color: #172b4d; font-weight: 900; font-size: 24px;">
      <el-icon><Cpu /></el-icon> API Key管理
    </p>

    <el-form :inline="true" :model="filters" class="filter-form">
      <el-form-item label="API Key">
        <el-input v-model="filters.api_key" placeholder="请输入API Key"></el-input>
      </el-form-item>
  
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
        <el-button @click="resetFilters">重置</el-button>
      </el-form-item>
  
      <el-form-item>
        <el-button type="primary" @click="openCreateDialog">新增API Key</el-button>
      </el-form-item>
    </el-form>
  
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column prop="api_key" label="API Key"></el-table-column>
      <el-table-column prop="server_ips" label="服务器白名单"></el-table-column>
      <el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间" width="180"></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="{ row }">
          <el-button type="primary" @click="openEditDialog(row)" size="small">编辑</el-button>
          <el-button type="danger" @click="deleteItem(row.id)" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  
    <el-pagination
      background
      layout="prev, pager, next, total"
      :total="total"
      :page-size="limit"
      v-model:current-page="page"
      @current-change="fetchData"
    ></el-pagination>

    <!-- 新增/编辑对话框 -->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="50%"
      @close="resetForm"
    >
      <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
        <el-form-item label="API Key" prop="api_key">
          <div style="display: flex; gap: 10px;">
            <el-input v-model="formData.api_key" placeholder="请输入API Key"></el-input>
            <el-button type="primary" @click="generateApiKey" v-if="!isEdit">生成Key</el-button>
          </div>
        </el-form-item>
        
        <el-form-item label="服务器白名单" prop="server_ips">
          <el-input 
            v-model="formData.server_ips" 
            placeholder="多个IP用逗号分隔，例如: 192.168.1.1,192.168.1.2"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
      </el-form>
      
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import axios from '../axiosInstance';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Cpu } from '@element-plus/icons-vue';

export default {
  components: {
    Cpu
  },
  setup() {
    const tableData = ref([]);
    const total = ref(0);
    const page = ref(1);
    const limit = ref(20);
    const dialogVisible = ref(false);
    const dialogTitle = ref('');
    const isEdit = ref(false);
    const currentId = ref(null);
    const formRef = ref(null);

    const filters = reactive({
      api_key: ''
    });

    const formData = reactive({
      api_key: '',
      server_ips: ''
    });

    const rules = reactive({
      api_key: [
        { required: true, message: '请输入API Key', trigger: 'blur' },
        { min: 8, message: 'API Key长度不能少于8个字符', trigger: 'blur' }
      ],
      server_ips: [
        { required: true, message: '请输入服务器白名单', trigger: 'blur' }
      ]
    });

    const fetchData = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/ad-api', {
          params: {
            page: page.value,
            limit: limit.value,
            filter: filters
          }
        });
        tableData.value = response.data.data;
        total.value = response.data.pagination.total;
      } catch (error) {
        ElMessage.error('获取数据失败: ' + error.message);
      }
    };

    const resetFilters = () => {
      filters.api_key = '';
      page.value = 1;
      fetchData();
    };

    const openCreateDialog = () => {
      dialogTitle.value = '新增API Key';
      isEdit.value = false;
      dialogVisible.value = true;
    };

    const openEditDialog = (row) => {
      dialogTitle.value = '编辑API Key';
      isEdit.value = true;
      currentId.value = row.id;
      // 直接使用行数据填充表单
      formData.api_key = row.api_key;
      formData.server_ips = row.server_ips;
      dialogVisible.value = true;
    };

    const generateApiKey = () => {
      // 生成一个随机的API Key
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let key = '';
      for (let i = 0; i < 32; i++) {
        key += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      formData.api_key = key;
    };

    const submitForm = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          try {
            if (isEdit.value) {
              await axios.put(`/api/apifoadmin/ad-api/${currentId.value}`, formData);
              ElMessage.success('更新成功');
            } else {
              await axios.post('/api/apifoadmin/ad-api', formData);
              ElMessage.success('创建成功');
            }
            dialogVisible.value = false;
            fetchData();
          } catch (error) {
            ElMessage.error('操作失败: ' + error.message);
          }
        }
      });
    };

    const deleteItem = (id) => {
      ElMessageBox.confirm('确定要删除此API Key吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          await axios.delete(`/api/apifoadmin/ad-api/${id}`);
          ElMessage.success('删除成功');
          fetchData();
        } catch (error) {
          ElMessage.error('删除失败: ' + error.message);
        }
      }).catch(() => {});
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      currentId.value = null;
      // 重置表单数据
      formData.api_key = '';
      formData.server_ips = '';
    };

    onMounted(() => {
      fetchData();
    });

    return {
      tableData,
      total,
      page,
      limit,
      filters,
      formData,
      rules,
      formRef,
      dialogVisible,
      dialogTitle,
      fetchData,
      resetFilters,
      openCreateDialog,
      openEditDialog,
      generateApiKey,
      submitForm,
      deleteItem,
      resetForm
    };
  }
};
</script>

<style scoped>
.filter-form {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-table {
  margin-top: 20px;
}

.el-pagination {
  margin-top: 20px;
  justify-content: flex-end;
}
</style>